.error-boundry{
    text-align: center;
    margin-top: 24vh;
    display: flex;
    flex-direction: column;
}
.error-boundry svg{
    font-size: 80px;
    color: #ccc;
}
.error-boundry b{
   margin-top: 20px;
}
.error-boundry small{
   max-width: 60%;
   margin: auto;
   margin-top: 10px;
   opacity: 0.6;
}
.error-boundry a{
   margin-top: 30px;
   color: #0000ff;
}